.about {
  max-width: 1080px;
  display: flex;
  align-items: center;
  margin: 400px auto 350px auto;
  gap: 40px;
}

.about img {
  position: absolute;
  left: -250px;
  z-index: -1;
  transform: rotate(-4deg);
  filter: opacity(80%);
}

.about img.animate {
  transform: rotate(-4deg);
}

.about-title-container {
  flex: 1;
}

.about p {
  align-self: flex-end;
  flex: 1;
}

/* Breakpoints */

@media (max-width: 1079px) {
  .about {
    flex-direction: column;
    margin-top: -120px;
    margin-bottom: 400px;
    gap: 12px;
  }

  .about img {
    left: auto;
    margin: 0 0 0 auto;
    position: relative;
    transform: translateY(80%) rotate(-2deg);
  }
}

@media (max-width: 700px) {
  .about {
    margin-top: 0;
    margin-bottom: 120px;
  }

  .about img {
    left: auto;
    margin: 0 0 0 auto;
    position: relative;
    transform: translateY(150px) rotate(-2deg);
    height: 300px;
  }
}
