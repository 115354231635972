.button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-colour);
  background-color: white;
  border-radius: 12px;
  transition: all 200ms ease-in-out;
  width: fit-content;
  white-space: nowrap;
}

.button path {
  fill: var(--primary-colour);
}

.button:hover {
  background-color: var(--primary-colour);
  color: white;
}

.button:hover path {
  fill: white;
}

.button svg {
  width: 24px;
  transition: all 200ms ease-in-out;
}

.button:hover svg {
  transform: rotate(45deg);
}

.secondary-button {
  color: white;
  background-color: transparent;
  border: solid 1px white;
  font-weight: 600;
}

.secondary-button:hover {
  border: solid 1px var(--primary-colour);
  background-color: var(--primary-colour);
}
