.checkerboard {
  margin-top: 200px;
  display: flex;
  align-items: center;
  gap: 80px;
}

.checkerboard-image {
  width: 100%;
}

.checkerboard img {
  width: 100%;
  border-radius: 4px;
}

.checkerboard-content {
  width: 70%;
}

.checkerboard a {
  margin-top: 24px;
}

/*Breakpoints*/

@media (max-width: 1079px) {
  .checkerboard {
    flex-direction: column-reverse;
    gap: 64px;
  }

  .checkerboard p {
    margin-top: 24px;
  }

  .checkerboard-content {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .checkerboard {
    margin-top: 120px;
  }
}
