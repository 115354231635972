.more-projects {
  margin-top: 200px;
}

.more-projects .split-section {
  align-items: end;
}

/*Breakpoints*/

@media (max-width: 1079px) {
  .more-projects .split-section {
    flex-direction: row;
  }

  .more-projects a {
    margin-top: 12px;
  }

  .more-projects .split-cards {
    gap: 64px;
  }
}

@media (max-width: 700px) {
  .more-projects {
    margin-top: 120px;
  }

  .more-projects .split-section {
    flex-direction: column;
    align-items: start;
  }
}
