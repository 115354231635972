.fade-in {
  opacity: 0;
  transform: translateY(80px); /* Initial position off-screen */
  transition: opacity 0.8s ease, transform 0.8s ease; /* Transition for opacity and transform */
}

.fade-in.active {
  opacity: 1;
  transform: translateY(0); /* Slide up to original position */
}
