.skills {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 200px;
  gap: 80px;
}

.skills ul {
  width: 800px;
  min-width: 300px;
  margin: 0;
}

.skills li {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 32px 0;
  list-style-type: none;
  border-top: rgba(255, 255, 255, 0.25) solid 1px;
}

.skills h4 {
  font-size: 32px;
}

/*Breakpoints*/

@media (max-width: 700px) {
  .skills {
    gap: 40px;
    margin-top: 80px;
    flex-direction: column;
  }

  .skills ul {
    width: 100%;
    padding: 0;
  }

  .skills li {
    padding: 24px 0;
  }

  .skills h4 {
    font-size: 24px;
  }
}
