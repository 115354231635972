.left-text-section {
  margin-top: 120px;
}

.left-side-text {
  display: block;
  text-align: left;
  max-width: 900px;
}

@media (max-width: 700px) {
  .left-text-section {
  }
}
