.feature-image {
  width: 100%;
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-gif {
  max-height: 500px;
  margin: 40px;
  border-radius: 24px;
  overflow: hidden;
}

/*Breakpoints*/

@media (max-width: 700px) {
  .feature-image {
    min-height: auto;
    height: 500px;
  }

  .feature-gif {
    border-radius: 16px;
    width: 90%;
    margin: 0;
  }
}
