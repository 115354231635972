.main-content {
  max-width: 1304px;
  padding: 0 40px;
  margin: 0 auto;
}

.landing-page {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.landing-page h1 {
  margin-bottom: 80px;
  text-align: center;
}

.Pill {
  display: inline-flex;
  white-space: nowrap;
  margin: 0 0 24px 0;
  color: white;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 100px;
  border: solid 1px rgba(255, 255, 255, 0.12);
}

.split-section {
  display: flex;
  justify-content: space-between;
  gap: 80px;
  margin-top: 120px;
}
.split-section .left-side {
  flex: 1;
}

.split-section .right-side {
  flex: 1;
}

.split-cards {
  margin-top: 80px;
  display: flex;
  gap: 80px;
}

/*Breakpoints*/

@media (max-width: 1079px) {
  .split-section {
    gap: 12px;
    flex-direction: column;
  }

  .split-cards {
    flex-direction: column;
    gap: 0;
  }
}

@media (max-width: 700px) {
  .hero {
    height: auto;
  }

  .Pill {
    margin-bottom: 16px;
  }

  .split-section {
    margin-top: 120px;
  }
}
