.split-images {
  display: flex;
  justify-content: space-between;
  gap: 80px;
  margin-top: 80px;
}

.split-images-container {
  width: 100%;
  padding-top: 38%;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
}

.image-left {
  margin-bottom: 120px;
}

.image-right {
  margin-top: 120px;
}

/*Breakpoints*/

@media (max-width: 1079px) {
  .split-images {
    margin-top: 40px;
    flex-direction: column;
    gap: 40px;
  }

  .split-images-container {
    padding-top: 70%;
  }

  .image-left {
    margin: 0 auto;
  }

  .image-right {
    margin: 0 auto;
  }
}

@media (max-width: 700px) {
  .split-images {
    gap: 24px;
    flex-direction: column;
  }

  .split-images-container {
    width: 100%;
    padding-top: 70%;
  }
}
