.stagger-margin {
  margin-top: 80px;
}

.project-card {
  width: 100%;
}

.project-card .card-icon {
  transition: all 200ms ease-in-out;
  height: 32px;
}

.project-card:hover .card-icon {
  transform: rotate(45deg);
}

.project-card-image {
  padding-top: 70%;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  filter: opacity(85%);
  transition: all 200ms ease-in-out;
}

.project-card:hover .project-card-image {
  filter: opacity(100%);
}

.project-card-details {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 1079px) {
  .stagger-margin {
    margin-top: 0;
  }
}
