.feature-project-card {
  padding: 64px 0;
  display: flex;
  justify-content: space-between;
  gap: 80px;
  border-bottom: rgba(255, 255, 255, 0.25) solid 1px;
}

.feature-project-card .card-left {
  display: flex;
  width: 60%;
  justify-content: space-between;
  gap: 8px;
}

.feature-project-card .card-details {
  display: flex;
  flex-direction: column;
}

.feature-project-card .card-image {
  width: 100%;
  border-radius: 4px;
  filter: opacity(85%);
  transition: all 200ms ease-in-out;
}

.feature-project-card h3 {
  color: rgba(255, 255, 255, 0.9);
}

.feature-project-card:hover h3 {
  color: white;
}

.feature-project-card p {
  margin-top: 12px;
}

.feature-project-card .card-icon {
  height: 32px;
  transform: rotate(0deg);
  transition: all 200ms ease-in-out;
}

.feature-project-card:hover .card-icon {
  transform: rotate(45deg);
}

.feature-project-card:hover .card-image {
  filter: opacity(100%);
}

/*Breakpoints*/

@media (max-width: 1079px) {
  .feature-project-card {
    gap: 24px;
    flex-direction: column-reverse;
  }

  .feature-project-card .card-left {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .feature-project-card {
    flex-direction: column-reverse;
    gap: 24px;
    padding: 40px 0;
  }

  .feature-project-card .card-left {
    width: 100%;
  }

  .feature-project-card h3 {
    color: white;
  }

  .feature-project-card .card-image {
    height: auto;
    width: 100%;
    filter: opacity(100%);
  }
}
