html,
body {
  overflow-x: hidden;
  color: white;
  font-family: "Manrope", sans-serif;
}

body {
  margin: 0;
  background-color: black;
  overflow-x: hidden;
}

:root {
  --primary-colour: #7b79ff;
}

h1 {
  font-size: 120px;
  margin: 0;
  line-height: 100%;
  letter-spacing: -0.04em;
  font-weight: 900;
}

h2 {
  font-size: 40px;
  line-height: 44px;
  margin: 0;
}

h3 {
  font-size: 36px;
  line-height: 40px;
  margin: 0;
}

h4 {
  font-size: 24px;
  line-height: 26.5px;
  margin: 0;
}

p {
  font-size: 16px;
  margin: 0;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
}

a {
  text-decoration: none;
  color: white;
}

ol {
  padding: 0;
}

li {
  list-style: none;
}

.highlight-text {
  color: var(--primary-colour);
}

.highlight-p {
  font-weight: bold;
  color: white;
}

.p-large {
  font-size: 18px;
}

.p-jumbo {
  font-size: 20px;
}

.right-align {
  text-align: right;
}

@media (max-width: 700px) {
  .main-content {
    padding: 0 16px;
  }

  .Jumbo-text {
    font-size: 4em;
    text-align: center;
    margin: 0;
  }

  h1 {
    font-size: 52px;
    letter-spacing: -2px;
    line-height: 58px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 28px;
  }

  .p-jumbo {
    font-size: 18px;
  }
}
