.projects-list {
  margin-top: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;
}

.project-page-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 64px 0;
}

.project-filter {
  display: flex;
  gap: 12px;
}

@media (max-width: 1079px) {
  .projects-list {
    grid-template-columns: 1fr;
    width: 80%;
    margin: 64px auto 0 auto;
  }

  .project-card {
    border-bottom: rgba(255, 255, 255, 0.25) solid 1px;
    padding-bottom: 64px;
  }
}

@media (max-width: 700px) {
  .projects-list {
    width: 100%;
    gap: 40px;
  }

  .project-card {
    padding-bottom: 40px;
  }

  .split-cards {
    margin-top: 40px;
  }
}
