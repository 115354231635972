.split-list {
  margin-top: 200px;
}

.split-list ol,
p {
  margin-top: 12px;
}

.split-list li {
  margin-bottom: 40px;
}

.split-list li::marker {
  font-size: 24px;
  font-weight: bold;
}

/*Breakpoints*/

@media (max-width: 1079px) {
  .split-list ol {
    margin-top: 40px;
  }
}

@media (max-width: 700px) {
  .split-list {
    margin-top: 120px;
  }
}
