.feature-projects {
  margin-top: 120px;
}

.more-projects-link {
  display: inline-flex;
  gap: 8px;
  margin-top: 24px;
}

.more-projects-link p {
  color: white;
  transition: all 200ms ease-in-out;
}

.more-projects-link:hover p {
  color: var(--primary-colour);
}

.more-projects-link .card-icon {
  width: 28px;
  margin-top: 12px;
  transition: all 200ms ease-in-out;
}

.more-projects-link:hover .card-icon {
  transform: rotate(45deg);
}

/*Breakpoints*/

@media (max-width: 700px) {
  .feature-projects {
    margin-top: 80px;
  }
}
