.hero-section {
  display: flex;
  flex-direction: column;
  overflow: visible;
  max-width: 1304px;
  height: 90vh;
  justify-content: center;
}

.hero-section h1 {
  font-size: 250px;
  line-height: 80%;
}

.hero-heading {
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin: 0 0 80px 0;
}

.hero-heading-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.hero-right {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.hero-heading img {
  width: 85px;
}

.hero-description-desktop {
  width: 335px;
}

.hero-description-mobile {
  display: none;
  width: 320px;
}

/* Breakpoints */

@media (max-width: 1079px) {
  .hero-section {
    height: auto;
    justify-content: flex-start;
  }

  .hero-heading h1 {
    font-size: clamp(20px, 20vw, 250px);
  }

  .hero-heading {
    gap: 24px;
    align-items: flex-start;
    margin: 40px 0 80px 0;
  }

  .hero-heading-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .hero-description-desktop {
    display: none;
  }

  .hero-description-mobile {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 400px;
    margin-top: 64px;
  }
}

@media (max-width: 700px) {
  .hero-heading {
    margin: 64px 0;
  }

  .hero-description-mobile {
    gap: 12px;
  }
}
