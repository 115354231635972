.project-header {
  margin-top: 64px;
  margin-bottom: 120px;
}

.project-overview {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.project-overview a {
  align-self: flex-end;
}

.project-overview p {
  margin-top: 24px;
  width: 600px;
}

.project-details ul {
  margin: 24px 0 0 0;
  padding: 0;
}

.project-details li {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: rgba(255, 255, 255, 0.25) solid 1px;
}

.project-details .border-none {
  border: none;
}

/*Breakpoints*/

@media (max-width: 1079px) {
  .project-overview {
    flex-direction: column;
    gap: 0;
  }

  .project-overview a {
    align-self: flex-start;
    margin: 24px 0;
  }
}

@media (max-width: 700px) {
  .project-header {
    margin-top: 64px;
    margin-bottom: 80px;
  }

  .project-overview {
    gap: 0;
  }

  .project-overview p {
    width: 100%;
  }
}
