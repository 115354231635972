.footer-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0 40px 0;
  gap: 40px;
  border-top: rgba(255, 255, 255, 0.25) solid 1px;
}

.footer-mobile {
  display: none;
}

.footer p {
  color: rgba(255, 255, 255, 0.5);
}

.footer a p {
  color: rgba(255, 255, 255, 0.8);
  transition: all 150ms ease-in-out;
}

.footer a p:hover {
  color: white;
}

@media (max-width: 700px) {
  .footer-desktop {
    display: none;
  }

  .footer-mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 0;
    border-top: rgba(255, 255, 255, 0.25) solid 1px;
    align-items: center;
  }

  .socials-mobile {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 40px;
  }
}
