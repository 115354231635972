.navigation {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  max-width: 1304px;
  margin: 0 auto;
  border-bottom: rgba(255, 255, 255, 0.25) solid 1px;
  z-index: 2;
  position: relative;
}

.navigation p {
  margin: 0;
  color: white;
}

.navigation img {
  margin-top: 8px;
  height: 28px;
}

.nav-left {
  display: flex;
  gap: 32px;
  align-items: center;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 40px;
}

.nav-links {
  display: flex;
  gap: 24px;
}

.nav-links a {
  color: rgba(255, 255, 255, 0.8);
  transition: all 150ms ease-in-out;
}

.nav-links a:hover {
  color: white;
}

.nav-links li {
  margin: 0;
}

.pulse-text {
  display: flex;
  gap: 12px;
  align-items: center;
}

/*Breakpoints*/

@media (max-width: 1079px) {
  .pulse-text {
    display: none;
  }
}

@media (max-width: 700px) {
  .nav-links {
    display: none;
  }
}
